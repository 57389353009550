import axios from 'axios'

const endpointUrl = (
  process.env.NODE_ENV === 'development' ?
  'http://localhost:8080/mailtrap_campaigns_http' :
  '/mailtrap_campaigns_http'
)

export const makeRequest = (idToken, requestSchema = {}) => {
  const {headers = {}, ...rest} = requestSchema

  return axios({
    method: 'get',
    url: endpointUrl,
    timeout: 40000,
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: true,
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json; charset=utf-8'
    },
    ...rest
  })
}
