import React from 'react'
import {Provider} from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import initializeStore from 'state/initializeStore'

dayjs.extend(utc)
dayjs.extend(relativeTime)

const Wrapper = (isBrowser = false) => ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = initializeStore()

  return (
    <Provider store={store}>{element}</Provider>
  )
}

export default Wrapper
