import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  fetchSqlQueryResultRequest,
  fetchSqlQueryResultSuccess,
  fetchSqlQueryResultFailure
} from 'actions/campaigns'

const byCampaignUuids = createReducer(
  {
    [fetchSqlQueryResultSuccess]: (state, {uuid, response: {data}}) => ({
      ...state,
      [uuid]: data
    })
  },
  {}
)

const isLoading = createReducer(
  {
    [fetchSqlQueryResultRequest]: () => true,
    [fetchSqlQueryResultSuccess]: () => false,
    [fetchSqlQueryResultFailure]: () => false
  },
  false
)

const hasError = createReducer(
  {
    [fetchSqlQueryResultRequest]: () => false,
    [fetchSqlQueryResultSuccess]: () => false,
    [fetchSqlQueryResultFailure]: (_state, payload) => payload
  },
  false
)

export const reducer = combineReducers({
  byCampaignUuids,
  isLoading,
  hasError
})
