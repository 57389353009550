import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
  hideFlashMessage
} from 'actions/flashMessage'
import {
  addCampaignSuccess,
  updateCampaignSuccess,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  sendTestingEmailSuccess,
  sendTestingEmailFailure
} from 'actions/campaigns'

// message types
const successMessageType = (message) => ({message, type: 'success'})
const errorMessageType = (message) => ({message, type: 'error'})

const flashMessageData = createReducer(
  {
    // basic
    [showSuccessFlashMessage]: (_state, payload) => successMessageType(payload),
    [showErrorFlashMessage]: (_state, payload) => errorMessageType(payload),
    // actions
    [addCampaignSuccess]: () => () => successMessageType('Campaign is added successfully'),
    [updateCampaignSuccess]: () => successMessageType('Campaign is updated successfully'),
    [deleteCampaignSuccess]: () => successMessageType('Campaign is deleted successfully'),
    [deleteCampaignFailure]: () => errorMessageType('Failed to delete campaign'),
    [sendTestingEmailSuccess]: () => successMessageType('Test email successfully send'),
    [sendTestingEmailFailure]: () => errorMessageType('Failed to send test email'),
    [hideFlashMessage]: () => ({}),
  },
  {}
)

export const reducer = combineReducers({
  data: flashMessageData
})
