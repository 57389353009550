import {combineReducers} from 'redux'

import {reducer as auth} from 'reducers/auth'
import {reducer as flashMessage} from 'reducers/flashMessage'
import {reducer as campaigns} from 'reducers/campaigns'
import {reducer as sqlQuery} from 'reducers/sqlQuery'
import {reducer as qaSqlQuery} from 'reducers/qaSqlQuery'

export default combineReducers({
  auth,
  flashMessage,
  campaigns,
  sqlQuery,
  qaSqlQuery
})
