import {createStore, applyMiddleware, compose} from 'redux'
import {createLogger} from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import {loggers} from 'redux-act'
import rootSaga from 'sagas/root'
import rootReducer from './rootReducer'

export const initializeStore = (preloadedState = null) => {
  const sagaMiddleware = createSagaMiddleware()

  let middlewares = [sagaMiddleware]

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(
      createLogger({
        ...loggers.reduxLogger,
        level: 'info',
        duration: true,
        timestamp: true,
        collapsed: true,
        diff: true
      })
    )
  }

  const store = (() => {
    let enhancers = applyMiddleware(...middlewares)
    if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
      const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      enhancers = composeEnhancers(enhancers)
    }
    let storeArg = [rootReducer, enhancers]

    if (preloadedState) {
      storeArg.splice(1, 0, preloadedState)
    }

    return createStore(...storeArg)
  })()

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    module.hot.accept('./rootReducer', () => (
      store.replaceReducer(rootReducer)
    ))
  }

  return store
}

export default initializeStore
