import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  updateAuthUser,
  resetAuthUser
} from 'actions/auth'

const user = createReducer(
  {
    [updateAuthUser]: (_state, payload) => payload,
    [resetAuthUser]: () => null
  },
  null
)

export const reducer = combineReducers({
  user
})
