import {createAction} from 'redux-act'

export const fetchCampaignsRequest = createAction('Request to fetch campaigns')
export const fetchCampaignsSuccess = createAction('Success in fetching campaigns')
export const fetchCampaignsFailure = createAction('Failure in fetching campaigns')

export const addCampaignRequest = createAction('Request to add campaign')
export const addCampaignSuccess = createAction('Success in add campaign')
export const addCampaignFailure = createAction('Failure in add campaign')

export const updateCampaignRequest = createAction('Request to update campaign')
export const updateCampaignSuccess = createAction('Success in update campaign')
export const updateCampaignFailure = createAction('Failure in update campaign')

export const deleteCampaignRequest = createAction('Request to delete campaign')
export const deleteCampaignSuccess = createAction('Success in delete campaign')
export const deleteCampaignFailure = createAction('Failure in delete campaign')

export const fetchSqlQueryResultRequest = createAction('Request to fetch SQL query result')
export const fetchSqlQueryResultSuccess = createAction('Success in fetching SQL query result')
export const fetchSqlQueryResultFailure = createAction('Failure in fetching SQL query result')

export const qaCampaignRequest = createAction('Request to QA campaign')
export const qaCampaignSuccess = createAction('Success in QA campaign')
export const qaCampaignFailure = createAction('Failure in QA campaign')

export const sendTestingEmailRequest = createAction('Request to send test email')
export const sendTestingEmailSuccess = createAction('Success in sending test email')
export const sendTestingEmailFailure = createAction('Failure in sending test email')
