import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'
import dayjs from 'dayjs'
import {
  fetchCampaignsRequest,
  fetchCampaignsSuccess,
  fetchCampaignsFailure,
  addCampaignSuccess,
  updateCampaignSuccess,
  deleteCampaignSuccess
} from 'actions/campaigns'

const normalizeCampaign = (campaign) => {
  const emailHeaders = (() => {
    if (!campaign['email_headers']) {
      return []
    }

    try {
      return JSON.parse(campaign['email_headers'])
    } catch (error) {
      console.info(error)
      return []
    }
  })()

  return {
    ...campaign,
    'email_headers': emailHeaders,
    'next_perform_at': dayjs(campaign.next_perform_at?.value).toDate()
  }
}

const getNormalizedCampaigns = (list) => list.map((campaign) => normalizeCampaign(campaign))

const allIds = createReducer(
  {
    [fetchCampaignsSuccess]: (_state, {response: {data}}) => data.map((c) => c.uuid),
    [addCampaignSuccess]: (state, {response: {data}}) => [data.uuid, ...state],
    [deleteCampaignSuccess]: (state, {uuid}) => state.filter((campaignUuid) => uuid !== campaignUuid)
  },
  []
)

const byUuid = createReducer(
  {
    [fetchCampaignsSuccess]: (state, {response: {data}}) => ({
      ...state,
      ..._keyBy((getNormalizedCampaigns(data)), 'uuid')
    }),
    [addCampaignSuccess]: (state, {response: {data}}) => ({
      ...state,
      [data.uuid]: normalizeCampaign(data)
    }),
    [updateCampaignSuccess]: (state, {uuid, response: {data}}) => ({
      ...state,
      [uuid]: normalizeCampaign(data)
    }),
    [deleteCampaignSuccess]: (state, {uuid}) => _omit(state, [uuid]),
  },
  {}
)

const isLoading = createReducer(
  {
    [fetchCampaignsRequest]: () => true,
    [fetchCampaignsSuccess]: () => false,
    [fetchCampaignsFailure]: () => false
  },
  false
)

const hasError = createReducer(
  {
    [fetchCampaignsRequest]: () => false,
    [fetchCampaignsSuccess]: () => false,
    [fetchCampaignsFailure]: (_state, payload) => payload
  },
  false
)

const isRequestLoaded = createReducer(
  {
    [fetchCampaignsSuccess]: () => true,
    [fetchCampaignsFailure]: () => true
  },
  false
)

export const reducer = combineReducers({
  allIds,
  byUuid,
  isLoading,
  hasError,
  isRequestLoaded
})
