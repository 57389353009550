import {put, call, select, takeLatest} from 'redux-saga/effects'
import {makeRequest} from 'httpApi/instance'
import {getUser} from 'selectors/auth'
import {showErrorFlashMessage} from 'actions/flashMessage'
import {
  fetchCampaignsRequest,
  fetchCampaignsSuccess,
  fetchCampaignsFailure,
  addCampaignRequest,
  addCampaignSuccess,
  addCampaignFailure,
  updateCampaignRequest,
  updateCampaignSuccess,
  updateCampaignFailure,
  deleteCampaignRequest,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  fetchSqlQueryResultRequest,
  fetchSqlQueryResultSuccess,
  fetchSqlQueryResultFailure,
  qaCampaignRequest,
  qaCampaignSuccess,
  qaCampaignFailure,
  sendTestingEmailRequest,
  sendTestingEmailSuccess,
  sendTestingEmailFailure
} from 'actions/campaigns'
import {showBackendErrors} from './helpers'

const getNormalizedCampaign = (data, uuid = null) => ({
  ...(uuid ? {uuid} : {}),
  ...data,
  ...(data['email_headers'] ? {'email_headers': JSON.stringify(data['email_headers'])} : {}),
  ...(data['next_perform_at'] ? {'next_perform_at': data['next_perform_at'].toISOString()} : {})
})

export const fetchCampaigns = function* () {
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token)
      yield put(fetchCampaignsSuccess({response}))
    } else {
      yield put(fetchCampaignsFailure(true))
    }
  } catch (error) {
    yield put(fetchCampaignsFailure(error))
  }
}

export const addCampaign = function* ({payload}) {
  const {data, resolve, reject} = payload
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token, {method: 'post', data: getNormalizedCampaign(data)})
      yield put(addCampaignSuccess({response}))
      yield call(resolve, response.data)
    } else {
      yield put(addCampaignFailure(true))
    }
  } catch (error) {
    yield put(addCampaignFailure(error))
    yield call(showBackendErrors, error, reject)
  }
}

export const updateCampaign = function* ({payload}) {
  const {uuid, data, resolve, reject} = payload

  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token, {method: 'patch', data: getNormalizedCampaign(data, uuid)})
      yield put(updateCampaignSuccess({uuid, response}))
      yield call(resolve, response.data)
    } else {
      yield put(updateCampaignFailure(true))
    }
  } catch (error) {
    yield put(updateCampaignFailure(error))
    yield call(showBackendErrors, error, reject)
  }
}

export const deleteCampaign = function* ({payload: {uuid}}) {
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token, {method: 'delete', data: {uuid}})
      yield put(deleteCampaignSuccess({uuid, response}))
    } else {
      yield put(deleteCampaignFailure(true))
    }
  } catch (error) {
    yield put(deleteCampaignFailure(error))
    yield put(showErrorFlashMessage(error.message))
  }
}

export const fetchSqlQueryResult = function* ({payload: {uuid, query}}) {
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token, {params: {type: 'preview', 'sql_query': query, uuid}})
      yield put(fetchSqlQueryResultSuccess({uuid, response}))
    } else {
      yield put(fetchSqlQueryResultFailure(true))
    }
  } catch (error) {
    yield put(fetchSqlQueryResultFailure(error))
    yield put(showErrorFlashMessage(error.message))
  }
}

const qaCampaign = function* ({payload: {uuid, query, qaQuery}}) {
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token, {params: {type: 'qa', 'sql_query': query, qa_sql_query: qaQuery, uuid}})
      yield put(qaCampaignSuccess({uuid, response}))
    } else {
      yield put(qaCampaignFailure(true))
    }
  } catch (error) {
    yield put(qaCampaignFailure(error))
    yield put(showErrorFlashMessage(error.message))
  }
}

export const requestTestEmail = function* ({payload: {uuid, data}}) {
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(makeRequest, token, {method: 'post', data: {type: 'testSending', data, uuid}})
      yield put(sendTestingEmailSuccess({uuid, response}))
    } else {
      yield put(sendTestingEmailFailure(true))
    }
  } catch (error) {
    yield put(sendTestingEmailFailure(error))
    yield put(showErrorFlashMessage(error.message))
  }
}

export const watch = function* () {
  yield takeLatest(fetchCampaignsRequest, fetchCampaigns)
  yield takeLatest(addCampaignRequest, addCampaign)
  yield takeLatest(updateCampaignRequest, updateCampaign)
  yield takeLatest(deleteCampaignRequest, deleteCampaign)
  yield takeLatest(fetchSqlQueryResultRequest, fetchSqlQueryResult)
  yield takeLatest(qaCampaignRequest, qaCampaign)
  yield takeLatest(sendTestingEmailRequest, requestTestEmail)
}
