import {all, fork} from 'redux-saga/effects'

import {watch as watchCampaigns} from './campaigns'

const rootSaga = function* () {
  yield all([
    fork(watchCampaigns)
  ])
}

export default rootSaga
