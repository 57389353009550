import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  qaCampaignRequest,
  qaCampaignSuccess,
  qaCampaignFailure
} from 'actions/campaigns'

const results = createReducer(
  {
    [qaCampaignSuccess]: (state, {response: {data}}) => data
  },
  {}
)


const isLoading = createReducer(
  {
    [qaCampaignRequest]: () => true,
    [qaCampaignSuccess]: () => false,
    [qaCampaignFailure]: () => false
  },
  false
)

const hasError = createReducer(
  {
    [qaCampaignRequest]: () => false,
    [qaCampaignSuccess]: () => false,
    [qaCampaignFailure]: (_state, payload) => payload
  },
  false
)

export const reducer = combineReducers({
  results,
  isLoading,
  hasError
})
